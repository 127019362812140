<template>
  <ValidationObserver>
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="60"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Generate Request</span>
      </template>
      <div>
        <div class="flex min-w-max gap-5 items-center">
          <div class="border-b border-primary-gray-100 pb-6 w-full">
            <div class="flex items-center relative">
              <span class="absolute ltr:left-4 top-2.5 z-10">
                <Icon class="icon" icon="searchGrey" height="20" width="20" />
              </span>
              <InputFieldWrapper class="w-full">
                <UiInput
                  v-model="query"
                  placeholder="SEARCH_STU"
                  title="Search Student"
                  type="text"
                  class="flex-1"
                  :no-error="true"
                  :hide-title="true"
                  @change="getStudents"
                />
              </InputFieldWrapper>
            </div>
          </div>
        </div>
        <div v-if="isEmpty(students)">
          <NoRecordFound custom-class="text-2xl" />
        </div>
        <div v-else>
          <TableWrapper v-if="!isLoading">
            <THead>
              <TRHead>
                <TH>Name</TH>
                <TH v-if="isAdmin">Pickup Person</TH>
                <TH v-for="(head, index) in tableHeaders" :key="index">{{ head }}</TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(student, index) in students" :key="index">
                <TD>
                  <SingleUserDisplay :user="student" label="full_name" :image="true" />
                </TD>
                <TD v-if="isAdmin">
                  <UiSingleSelect
                    v-model="student.pickupPersonId"
                    child-class="mb-0 "
                    title="Pickup Person"
                    label="full_name"
                    :options="student.student_info ? student.student_info.pickup_persons : []"
                    class="flex-1 bg-white rounded-lg"
                    rules="required"
                    :hide-title="true"
                    reduce="user_id"
                  />
                </TD>
                <TD>
                  <BadgeDisplay
                    :options="student.section_enrollment"
                    :nested-label="['section', 'class', 'title']"
                  />
                </TD>
                <TD>
                  <BadgeDisplay
                    :options="student.section_enrollment"
                    :nested-label="['section', 'title']"
                  />
                </TD>
                <TD>
                  <UIButton
                    button="primary"
                    :disabled="isRequestIsInProgress"
                    @click="generateRequest(student)"
                  >
                    Request
                  </UIButton>
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
        </div>
      </div>
      <template v-slot:footer></template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters, mapState } from 'vuex'
import UiInput from '@components/UiElements/UiInputBox.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import generalUtil from '@src/mixins/general-mixins.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    UiInput,
    NoRecordFound,
    UiSingleSelect,
    BadgeDisplay,
    SingleUserDisplay,
    InputFieldWrapper,
    UIButton,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalUtil],
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      isEmpty,
      students: [],
      isLoading: false,
      query: '',
      pickupPersonId: '',
      isTab: window.outerWidth < 768,
      isMobile: window.outerWidth <= 425,
      isRequestIsInProgress: false,
      tableHeaders: ['Class', 'Section', 'Actions'],
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope']),
    ...mapGetters('layout', ['userRole']),
    isAdmin() {
      return this.userRole === 'super_admin'
    },
    smallScreenWidth() {
      return this.isMobile ? `${screen.width * 0.9 - 40}px` : `${screen.width * 0.8 - 40}px`
    },
    tableWidth() {
      return {
        width: this.isTab ? this.smallScreenWidth : `${screen.width * 0.6 - 40}px`,
      }
    },
  },
  created() {
    this.getStudents()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'generate':
          this.$emit('toggle', 'fetch')
      }
    },
    getStudents(range) {
      if (this.isAdmin) this.getSectionStudents(this.query)
      else this.getPikcupAssosiatedStudents(this.query)
    },
    getSectionStudents(query = '') {
      this.isLoading = true
      let payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) },
      }
      this.getStudentsWithFilters(query ? payload : '').then((res) => {
        this.isLoading = false
        this.students = res?.records
        if (this.students?.length > 0) {
          this.students.forEach((record) => {
            record?.student_info?.pickup_persons.forEach((pikcup) => {
              pikcup.full_name = `${pikcup.first_name} ${pikcup.last_name}`
            })
          })
        }
      })
    },
    getPikcupAssosiatedStudents(query = '') {
      this.isLoading = true
      let payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) },
      }
      this.getAssociatedStudents(query ? payload : '').then((res) => {
        this.isLoading = false
        this.students = res?.records
        if (this.students?.length > 0) {
          this.students.forEach(
            (record) => (record.full_name = `${record.first_name} ${record.last_name}`),
          )
        }
      })
    },

    async generateRequest(student) {
      try {
        this.isRequestIsInProgress = true

        let payload = {
          student_id: student?.id,
          campus_id: this.currentCampusScope.id,
        }
        if (student.pickupPersonId) payload.pickup_person_id = student.pickupPersonId
        await this.generateNewPickupRequest(payload).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: 'Request Generated',
          })
        })
        this.handleClick('generate')
      } catch (err) {
        this.isRequestIsInProgress = false
      }
    },
    ...mapActions('pickup', ['getAssociatedStudents', 'generateNewPickupRequest']),
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" scoped>
.custom-w-h {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
}
@media (min-width: 524px) and(max-width: 639px) {
  .custom-max-w {
    max-width: 465px;
  }
}
@media (min-width: 425px) and(max-width: 510px) {
  .custom-max-w {
    width: 360px;
  }
}
@media (min-width: 374px) and(max-width: 424px) {
  .custom-max-w {
    width: 305px;
  }
}
@media (min-width: 350px) and(max-width: 374px) {
  .custom-max-w {
    width: 289px;
  }
}
@media (min-width: 320px) and(max-width: 349px) {
  .custom-max-w {
    width: 267px;
  }
}
</style>
