<template>
  <!-- <ValidationObserver tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Picked Up</span>
    </template>
    <template v-slot>
      <div v-if="isEmpty(requests)">
        <NoRecordFound custom-class="text-2xl" />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH v-for="(head, index) in tableHeaders" :key="index">{{ head }}</TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(request, index) in requests" :key="index">
            <TD>
              <SingleUserDisplay
                :user="request.student"
                label="full_name"
                :image="true"
                :dropdown-top-bottom="dropdownTopBottom(index, requests, 'bottom-6', 'top-6')"
                :tooltip="dropdownTopBottom(index, requests, 'tooltip-bottom', 'tooltip-top')"
              />
            </TD>
            <TD>
              <UIButton button="primary" @click="updateRequest(request)">
                Student Picked up
              </UIButton>
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
    </template>
    <template v-slot:footer></template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapActions, mapState } from 'vuex'
import { dropdownTopBottom } from '@src/utils/generalUtil.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import UIButton from '@/src/components/UiElements/UIButton.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    NoRecordFound,
    UiModalContainer,
    SingleUserDisplay,
    TBody,
    // ValidationObserver,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    UIButton,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
    requests: { type: Array, default: () => [] },
  },
  emits: ['toggle'],
  data() {
    return {
      isEmpty,
      tableHeaders: ['Name', 'Actions'],
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope']),
  },
  methods: {
    dropdownTopBottom,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'update':
          this.$emit('toggle', 'fetch')
      }
    },

    async updateRequest(request) {
      try {
        this.$store.commit('layout/IS_LOADING', true)

        await this.markStudentIsPickedUp({ payload: null, id: request.id }).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: 'Student Has Been Picked',
          })
        })
        this.handleClick('update')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('pickup', ['markStudentIsPickedUp']),
  },
}
</script>

<style>
@media screen and (max-width: 639px) and (min-width: 501px) {
  .s {
    max-width: 400px;
  }
}
@media screen and (max-width: 374px) {
  .xxs {
    max-width: 212px;
  }
}
</style>
