<template>
  <div>
    <PickupFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      title="Active Requests"
      tooltip-title="ACTIVE_REQ"
      @apply="applyFilters"
    />
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(requestsList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            {{ tableHeaders[0].dataTableName }}
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Last Three Activities')" colspan="2">
            {{ tableHeaders[1].dataTableName }}
          </TH>
          <TH
            v-for="head in tableHeaders.slice(2).filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, ['Pickup Person'])"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody
          v-for="(request, index) in requestsList"
          :key="index"
          :class="request.new && 'border border-primary-green'"
        >
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <span @click="redirectToStudentMode(request.student, 'active-request')">
              <SingleUserDisplay :user="request.student" label="full_name" :image="true" />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Last Three Activities')">
            <div
              v-for="(requestResponse, ind) in request.pickup_request_logs.slice(0, 3)"
              :key="ind"
              class="flex justify-around"
            >
              <span v-if="requestResponse.status === 'custom_message'">
                Message:"{{ requestResponse.message }}"
              </span>
              <span v-else>
                {{ requestResponse.message }}
              </span>
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Last Three Activities')">
            <div
              v-for="(requestTime, ind) in request.pickup_request_logs.slice(0, 3)"
              :key="ind"
              class="cursor-pointer text-primary-green"
              @click="$router.push({ name: 'request-details', params: { id: request.id } })"
            >
              {{ $filters.getTimeOnly(requestTime.inserted_at) }}
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Pickup Person', ['Pickup Person'])">
            <SingleUserDisplay
              :user="request.pickup_person"
              :required-items="1"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Main Guardian')">
            <SingleUserDisplay
              :user="request?.student?.student_info?.main_guardian"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Status')"
            class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 relative cursor-pointer"
            @click="$router.push({ name: 'request-details', params: { id: request.id } })"
          >
            <div :style="pickupRequestStyle(request.status)" class="uppercase text-center">
              <span :class="request.newLog && 'font-bold'">
                {{ request.status || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </span>
              <div
                v-if="request.newLog"
                class="absolute new-text text-xxs font-medium text-text-color-danger top-[3px] right-[17px]"
              >
                new
              </div>
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="request"
              :idx="index"
              :action-list="actionList"
              @action="userActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(requestsCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
    <GenerateRequestModal
      v-if="showRequestModal"
      :modal="showRequestModal"
      :edit="currentSelectedRequest"
      @toggle="toggleRequestModal"
    />
    <StudentPickedUpModal
      v-if="showPickupModal"
      :modal="showPickupModal"
      :edit="currentSelectedRequest"
      :requests="requestsList"
      @toggle="togglePickedUpModal"
    />
    <CloseRequestModal
      v-if="confirmation"
      :modal="confirmation"
      :current-request="currentSelectedRequest"
      @toggle="toggleConfirmationModal"
    />
  </div>
</template>

<script>
import CloseRequestModal from '@src/router/views/pickup/pickup-support/modal/CloseRequestModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import GenerateRequestModal from '@views/pickup/pickup-facility/modals/GeneratePickupRequest.vue'
import StudentPickedUpModal from '@views/pickup/pickup-facility/modals/StudentPickupModal.vue'
import { pickupRequestStyle, redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import PickupFilter from '@src/router/views/pickup/pickup-support/PickupRequestFilter.vue'
import PickupRequestData from '@src/router/views/pickup/pickup-support/pickup-request.json'
import fileMixins from '@/src/mixins/file-mixins'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { dropdownTopBottom, objectDeepCopy, showColumnInDataTable } from '@src/utils/generalUtil.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TableAction,
    Pagination,
    Loader,
    PickupFilter,
    CloseRequestModal,
    SingleUserDisplay,
    GenerateRequestModal,
    StudentPickedUpModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      TENANT_ROLES,
      isEmpty,
      FILTER_KEYS,
      isLoading: false,
      $where: {},
      tableHeaders: PickupRequestData.tableHeaders,
      defaultSelectedColumns: PickupRequestData.defaultSelectedColumns,
      actionList: [{ name: 'TA_RR' }, { name: 'TA_CR' }],
      GENERAL_CONSTANTS,
      currentSelectedRequest: {},
      filteredRecordLimit: 10,
      confirmation: false,
      showPagination: false,
      requestsList: [],
      filtersData: {},
      showRequestModal: false,
      showPickupModal: false,
      requestsCounts: 0,
    }
  },

  computed: {
    ...mapGetters('auth', ['subDomain']),
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      showModal: (state) => state.layout.showModal,
      showUiModal: (state) => state.layout.showUiModal,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentUser: (state) => state.auth.currentUser,
    }),
    showBreadcrumb() {
      return this.activeRole !== TENANT_ROLES.PICKUP_PERSON
    },
  },
  watch: {
    showModal: {
      handler() {
        this.toggleRequestModal()
      },
    },
    currentCampusScope: {
      handler() {
        this.filterRecords()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecords()
      },
    },
    showUiModal: {
      handler() {
        this.togglePickedUpModal()
      },
    },
  },

  mounted() {
    this.setRightbar()
    this.tableHeaders[5].skipForFilterOptions = true
    this.defaultSelectedColumns.splice(0, 1, 'Last Three Activities')
    this.tableHeaders[1].dataTableName = 'Last Three Activities'
    this.tableHeaders[1].backendKeyForCSV = 'Last Three Activities'
    this.tableHeaders[1].readableValueForCSV = 'Last Three Activities'
    this.filterRecords()
  },
  methods: {
    redirectToStudentMode,
    dropdownTopBottom,
    showColumnInDataTable,
    pickupRequestStyle,
    userActions(action, currentRequest, idx) {
      this.currentSelectedRequest = currentRequest
      this.currentUserPosition = idx
      switch (action) {
        case 'TA_RR':
          this.reRequest()
          break
        case 'TA_CR':
          this.toggleConfirmationModal()
          break
      }
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    toggleRequestModal(payload) {
      this.showRequestModal = !this.showRequestModal
      if (!this.showRequestModal) this.currentSelectedRequest = null
      if (payload === 'fetch') this.filterRecords()
    },

    togglePickedUpModal(payload) {
      this.showPickupModal = !this.showPickupModal
      if (!this.showPickupModal) this.currentSelectedRequest = null
      if (payload) this.filterRecords()
    },

    toggleConfirmationModal(payload) {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentSelectedRequest = null
      if (payload === 'fetch') this.filterRecords()
    },
    filterRecords(range) {
      this.requestLogs = []
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }

      payload = {
        ...payload,
        ...{
          $where: {
            ...this.filtersData?.$where,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'status', 'active'),
          },
        },
      }
      this.getAllPickupRequests(payload)
        .then((response) => {
          this.requestsList = response.records
          this.requestsCounts = response.meta.total_records
          this.requestsList.forEach((request) => {
            fullName(request.student)
            fullName(request.pickup_person)
            if (request.student?.student_info?.main_guardian)
              fullName(request.student?.student_info?.main_guardian)
          })
          this.initializePickupSocket()
          this.showPagination = this.requestsCounts > 10
          this.setRightbar(this.requestsCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    initializePickupSocket() {
      const authToken = localStorage.getItem('jwt') || null
      if (!this.$pickupSocket) this.$connectPickupSocket('/tenant-api-socket', authToken)

      if (this.$pickupSocket) {
        const pickupChannel = this.$pickupSocket.channel(
          `pickup_request:request_with_logs:${this.currentUser?.id}`,
        )

        pickupChannel.on('pickuprequestupdate', (log) => {
          let isAvailable = false
          let matchedRequest = null
          let matchedIndex = null
          fullName(log.student)
          fullName(log.pickup_person)
          this.requestsList.forEach((request, index) => {
            if (request.id === log.id) {
              isAvailable = true
              matchedRequest = request
              matchedIndex = index
            }
          })

          /**
           * When request is in list and list count is 10 or less moved request to top
           * When request is not available and list count is 10 removed one from end and add new on top
           * In other cased just move request to top
           */

          if (isAvailable && this.requestsList.length <= 10) {
            this.requestsList.splice(matchedIndex, 1)
            matchedRequest['newLog'] = true
            matchedRequest['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(matchedRequest)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          } else if (!isAvailable && this.requestsList.length === 10) {
            this.requestsList.pop()
            log['newLog'] = true
            log['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(log)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          } else {
            log['newLog'] = true
            log['pickup_request_logs'] = log.pickup_request_logs
            this.requestsList.unshift(log)
            if (log.closed_at || log.rejected_at || log.picked_at) {
              setTimeout(() => this.requestsList.splice(0, 1), 1000)
            }
          }

          this.$store.commit('toast/NEW', {
            message: ` ${log.pickup_request_logs[0].message}`,
            type: 'notification',
            image: log.pickup_request_logs[0].inserted_by.image,
            related_user: log.pickup_request_logs[0].inserted_by,
            inserted_at: log.pickup_request_logs[0].inserted_at,
          })
        })

        pickupChannel.onError(() => {
          // eslint-disable-next-line no-console
          console.log('Error')
        })
        pickupChannel
          .join()
          .receive('ok', ({ message }) => console.log(message)) // eslint-disable-line no-console
          .receive('error', ({ reason }) => console.log('failed join', reason)) // eslint-disable-line no-console
          .receive('timeout', () => console.log('Networking issue. Still waiting...')) // eslint-disable-line no-console
      }
    },
    reRequest() {
      this.reReuquestPickupRequest(this.currentSelectedRequest?.id).then(() => {
        this.filterRecords()
        this.currentSelectedRequest = null
      })
    },
    setRightbar(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Pickup Requests',
          buttons: [
            {
              title: 'Create Pickup Request',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
            {
              title: 'Student Picked Up',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'ACTIVE_REQUESTS',
                value: count,
                tooltipDescription: 'Total Active Request in Pickup Request Centre',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('pickup', [
      'getAllPickupRequests',
      'reReuquestPickupRequest',
      'closeActivePickupRequest',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<style lang="scss" scoped>
td.request-logs > div:not(:last-child) {
  border-bottom: 1px solid var(--primary-grey);
}
.new-text {
  font-size: 9px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
